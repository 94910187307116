.footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1px;
  background-color: #f8f6ff;
  width: 100%;
  min-height: 68px;

  &_IPhone {
    padding: 0 0 30px;
  }
}

.link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 4px solid var(--gray);
  transition: all 0.3s ease;

  &_active {
    border-bottom: 4px solid #fabb18;
  }
}

.main {
  width: 26px;
  height: 26px;
  background: url(../../assets/footer/footer_main.svg) center no-repeat;
}

.applications {
  width: 29px;
  height: 29px;
  background: url(../../assets/footer/footer_applications.svg) center no-repeat;
}

.users {
  width: 43px;
  height: 23px;
  background: url(../../assets/footer/footer_persons.svg) center no-repeat;
}

.chats {
  width: 42px;
  height: 42px;
  background: url(../../assets/footer/footer_chats.svg) center no-repeat;
}

.more {
  width: 29px;
  height: 29px;
  background: url(../../assets/footer/footer_more.svg) center no-repeat;
}

.text {
  color: #000;
  font-size: 13px;
  font-family: Roboto;
  line-height: 16px;
  margin-top: 5px;
}
